<template>
    <b-container v-if="item">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ item.id ? '修改選項' : '建立選項' }}</h3>
            </b-col>
            <b-col v-if="item.id">
                <b-button class="mt-3 float-right" variant="danger" @click="onDelete">刪除選項</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="item.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="短稱 (打印名稱)">
                <b-form-input v-model="item.shortName"></b-form-input>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { Item } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            item: null,
        }
    },

    async created() {
        this.item = this.action == 'create' ? Item() : await this.$store.dispatch('getDocument', { col: 'items', id: this.$route.params.id })
        if (!this.item) return this.$router.push('/items')
    },

    methods: {
        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'items', id: this.item.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/items')
        },
        
        async onSubmit() {
            this.item.merchantId = localStorage.merchantId

            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'items', doc: this.item })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/items')
        }
    }
}
</script>